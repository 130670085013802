<template>
  <div>
    <v-card>
      <v-row class="px-2 mb-2" align="center">
        <v-col cols="12" xs="12" sm="6" md="3">
          <v-autocomplete
            v-model="filter_company"
            hide-details
            :items="companies"
            item-text="nombre"
            item-value="id"
            dense
            chips
            small-chips
            :label="$t('reportes.filter_hospital')"
            background-color="white"
            class="mx-2"
            :v-tooltip="$t('reportes.filter_hospital')"
            @change="filterProfessionals"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                dense
                @click="data.select"
                @click:close="clearFilterCompany"
              >
                {{ data.item.nombre }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3">
          <v-autocomplete
            v-model="filter_professional"
            hide-details
            :items="filtered_professionals"
            item-text="nombreMarket"
            item-value="id"
            dense
            chips
            small-chips
            :label="$t('reportes.filter_professional')"
            background-color="white"
            class="mx-2"
            :v-tooltip="$t('reportes.filter_professional')"
            @change="getReporte"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                dense
                @click="data.select"
                @click:close="filter_professional = null"
              >
                {{ data.item.nombre + ' ' + data.item.apellidos }}
              </v-chip>
            </template>
            <template slot="item" slot-scope="data">
              <v-list-item-content>
                <v-list-item-title
                  >{{ data.item.nombreMarket }}
                  <span v-if="data.item.companyName">{{ ' (' + data.item.companyName + ')' }}</span></v-list-item-title
                >
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4">
          <filter-reports-by-date @change="handlePickerChange" />
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="2">
          <div class="text-center">
            <v-btn color="primary" depressed @click="getReporte">
              {{ $t('common.filtrar') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <div v-if="loading" class="text-center">
      <v-progress-circular :size="60" color="primary" indeterminate />
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="4">
          <v-card class="pa-4">
            <h3 class="display-1">{{ emailsActivationSent }}</h3>
            <div class="grey--text text--darken-2">{{ $t('reportes.activation_mails') }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4">
          <v-card class="pa-4">
            <h3 class="display-1">{{ professionalsActivated }}</h3>
            <div class="grey--text text--darken-2">{{ $t('reportes.activated_accounts') }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4">
          <v-card class="pa-4">
            <h3 class="display-1">{{ videoConsults }}</h3>
            <div class="grey--text text--darken-2">{{ $t('reportes.num_videocons') }}</div>
          </v-card>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="4">
          <v-card class="pa-4">
            <h3 class="display-1">{{ electronicPrescriptions }}</h3>
            <div class="grey--text text--darken-2">{{ $t('reportes.num_electronic_presc') }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4">
          <v-card class="pa-4">
            <h3 class="display-1">{{ pdfPrescriptions }}</h3>
            <div class="grey--text text--darken-2">{{ $t('reportes.num_pdf_presc') }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4">
          <v-card class="pa-4">
            <h3 class="display-1">{{ numChats }}</h3>
            <div class="grey--text text--darken-2">{{ $t('reportes.num_chats') }}</div>
          </v-card>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="4">
          <v-card class="pa-4">
            <h3 class="display-1">{{ registeredPatients }}</h3>
            <div class="grey--text text--darken-2">{{ $t('reportes.num_patients') }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4">
          <v-card class="pa-4">
            <h3 class="display-1">{{ numLogins }}</h3>
            <div class="grey--text text--darken-2">{{ $t('reportes.num_logins') }}</div>
          </v-card>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="6">
          <v-card class="pa-4">
            <div class="grey--text text--darken-2">{{ $t('reportes.graph_accounts') }}</div>
            <v-layout align-center justify-center class="py-5">
              <graphBar :chart-data="barChartData" :options="barChartOptions" />
            </v-layout>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6">
          <v-card class="pa-4">
            <div class="grey--text text--darken-2">{{ $t('reportes.graph_activity') }}</div>
            <v-layout align-center justify-center class="py-5">
              <graphPie :chart-data="pieChartData" :options="pieChartOptions" />
            </v-layout>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { getDoctors, companiesUrl, reportesUrl, getHeader } from '../../config/config';
import graphPie from '@/components/reportes/GraphPie.vue';
import graphBar from '@/components/reportes/GraphBar.vue';
import FilterReportsByDate from '@/components/reportes/FilterReportsByDate';

export default {
  name: 'ReportesVithas',
  components: {
    graphPie,
    graphBar,
    FilterReportsByDate,
  },
  props: {
    dash: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      loading: false,
      companies: [],
      all_professionals: [],
      filtered_professionals: [],
      filter_company: null,
      filter_professional: null,
      filter_picker1: null,
      filter_picker2: null,

      elastic: null,
      elasticUserId: null,
      elasticFrom: null,
      elasticTo: null,
      emailsActivationSent: 0,
      professionalsActivated: 0,
      registeredPatients: 0,
      electronicPrescriptions: 0,
      pdfPrescriptions: 0,
      videoConsults: 0,
      numChats: 0,
      numLogins: 0,
      numLogs: 0,

      searching_professional: false,
      searching_dates: false,

      reporte: null,

      pieChartData: {
        datasets: [
          {
            data: [0, 0, 0],
          },
        ],
      },
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },

      barChartData: {
        datasets: [
          {
            data: [0, 0],
          },
        ],
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1,
              },
            },
          ],
        },
      },
    };
  },

  mounted() {
    this.emailsActivationSent = this.dash.elastic.num_activations_emails;
    this.professionalsActivated = this.dash.elastic.num_activations;
    this.electronicPrescriptions = this.dash.elastic.num_electronic_prescriptions;
    this.pdfPrescriptions = this.dash.elastic.num_pdf_prescriptions;

    this.updateChartsData();
    this.getProfessionals();
    this.getCompanies();
    this.getReporte();
  },
  methods: {
    filterProfessionals() {
      const allProfessionals = this.all_professionals;
      const company = this.filter_company;
      if (!company) {
        this.filtered_professionals = allProfessionals;
        return;
      }
      this.filtered_professionals = allProfessionals.filter(obj => {
        return obj.company_id === company;
      });
      this.getReporte();
    },
    async getProfessionals() {
      try {
        const res = await this.$http.post(getDoctors, { id: this.authUser.id }, { headers: getHeader() });
        this.all_professionals = res.data.data;
        this.filtered_professionals = res.data.data;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    },
    getCompanies() {
      this.$http.post(companiesUrl, { id: this.authUser.id }, { headers: getHeader() }).then(response => {
        if (response.status === 200 && response.data) {
          this.loading = false;
          this.companies = response.data.empresas;
        }
      });
    },
    getReporte() {
      const data = {};
      this.searching_professional = false;
      this.searching_dates = false;

      if (this.filter_company) {
        data.filter_company = this.filter_company;
      }
      if (this.filter_professional) {
        this.searching_professional = true;
        data.filter_professional = this.filter_professional;
      }
      if (this.filter_picker1) {
        this.searching_dates = true;
        data.filter_from = this.formatDate(this.filter_picker1);
      }
      if (this.filter_picker2) {
        this.searching_dates = true;
        data.filter_to = this.formatDate(this.filter_picker2);
      }

      this.$http.get(reportesUrl, { headers: getHeader(), params: data }).then(response => {
        if (response.status === 200 && response.data) {
          this.loading = false;
          this.reporte = response.data.totales.elastic;

          this.emailsActivationSent = this.reporte.num_activations_emails;
          this.professionalsActivated = this.reporte.num_activations;
          this.electronicPrescriptions = this.reporte.num_electronic_prescriptions;
          this.pdfPrescriptions = this.reporte.num_pdf_prescriptions;
          this.numLogins = this.reporte.num_logins;
          this.numChats = this.reporte.num_chats;
          this.registeredPatients = this.reporte.num_patients;
          this.videoConsults = this.reporte.num_appointments;
          this.numLogs =
            this.emailsActivationSent +
            this.professionalsActivated +
            this.electronicPrescriptions +
            this.pdfPrescriptions +
            this.numLogins;

          this.updateChartsData();
        }
      });
    },
    updateChartsData() {
      this.pieChartData = {
        labels: [
          this.$t('reportes.chart_pdf_presc'),
          this.$t('reportes.chart_electronic_presc'),
          this.$t('reportes.num_videocons'),
          this.$t('reportes.num_chats'),
        ],
        datasets: [
          {
            data: [this.pdfPrescriptions, this.electronicPrescriptions, this.videoConsults, this.numChats],
            backgroundColor: [
              'rgba(100, 228, 172, 0.5)',
              'rgba(50, 157, 50, 0.5)',
              'rgba(33, 128, 165, 0.5)',
              'rgba(255, 147, 50, 0.5)',
            ],
          },
        ],
      };
      const noActivated = this.emailsActivationSent - this.professionalsActivated;
      this.barChartData = {
        labels: [this.$t('reportes.active_accounts'), this.$t('reportes.inactive_accounts')],
        datasets: [
          {
            label: 'Cuentas',
            data: [this.professionalsActivated, noActivated],
            backgroundColor: ['rgba(76, 175, 80, 0.5)', 'rgba(244, 67, 54, 0.5)'],
          },
        ],
      };
    },

    formatDate(date) {
      if (!date) return null;
      return this.$moment(date).format('YYYY-MM-DD');
    },

    handlePickerChange({ from, to } = {}) {
      this.filter_picker1 = from;
      this.filter_picker2 = to;
    },

    clearFilterCompany() {
      this.filter_company = null;
      this.filterProfessionals();
    },
  },
};
</script>
