<template>
  <div id="reportesMedyola">
    <v-expansion-panels focusable>
      <v-expansion-panel>
        <div slot="header" @click="abrirInforme(0)">
          <h4>{{ $t('agenda.disponibilidad') }}</h4>
        </div>
        <v-card>
          <v-divider />
          <br />
          <tabla-semanal
            :loading="loading"
            :headers-tabla="disponibilidad.headers"
            :items-tabla="disponibilidad.body"
          />
        </v-card>
      </v-expansion-panel>
      <v-expansion-panel>
        <div slot="header" @click="abrirInforme(1)">
          <h4>{{ $t('reportes.informe_productividad') }}</h4>
        </div>
        <v-card>
          <v-divider />
          <br />
          <v-layout class="filtroInforme">
            <v-flex xs6 sm8 lg8>
              <v-select
                v-model="profesional_id"
                :items="profesionales"
                :label="$t('user.prof_sanitario')"
                style="margin-top: 2px"
                auto
                required
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex xs6 sm4 lg4 class="text-sm-right">
              <v-btn :disabled="disabledButton" color="primary" @click="filtrarInforme(1)">
                <span><v-icon>mdi-magnify</v-icon>{{ $t('common.filtrar') }}</span>
              </v-btn>
            </v-flex>
          </v-layout>
          <span v-if="productividad.cargado">
            <v-toolbar dense color="transparent">
              <v-btn icon @click="modificarFecha(1, 'before', productividad.calendario.primer_dia_semana)"
                ><v-icon color="info">mdi-chevron-left</v-icon></v-btn
              >
              <v-spacer />
              {{ $t('common.semana') + ' ' + productividad.headers[1].text + ' - ' + productividad.headers[7].text }}
              <v-spacer />
              <v-btn icon @click="modificarFecha(1, 'after', productividad.calendario.ultimo_dia_semana)"
                ><v-icon color="info">mdi-chevron-right</v-icon></v-btn
              >
            </v-toolbar>
            <tabla-semanal
              :loading="loading"
              :headers-tabla="productividad.headers"
              :items-tabla="productividad.body"
            />
          </span>
        </v-card>
      </v-expansion-panel>
      <v-expansion-panel>
        <div slot="header" @click="abrirInforme(2)">
          <h4>{{ $t('reportes.tiempos_atencion_consulta') }}</h4>
        </div>
        <v-card>
          <v-divider />
          <br />
          <v-layout class="filtroInforme">
            <v-flex xs6 sm8 lg8>
              <v-select
                v-model="profesional_id"
                :items="profesionales"
                :label="$t('user.prof_sanitario')"
                style="margin-top: 2px"
                auto
                required
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex xs6 sm4 lg4 class="text-sm-right">
              <v-btn :disabled="disabledButton" color="primary" @click="filtrarInforme(2)">
                <span><v-icon>mdi-magnify</v-icon>{{ $t('common.filtrar') }}</span>
              </v-btn>
            </v-flex>
          </v-layout>
          <span v-if="tiempos.cargado">
            <v-toolbar dense color="transparent">
              <v-btn icon @click="modificarFecha(2, 'before', tiempos.calendario.primer_dia_semana)"
                ><v-icon color="info">mdi-chevron-left</v-icon></v-btn
              >
              <v-spacer />
              {{
                $t('common.semana') +
                  ' ' +
                  tiempos.calendario.primer_dia_front +
                  ' - ' +
                  tiempos.calendario.ultimo_dia_front
              }}
              <v-spacer />
              <v-btn icon @click="modificarFecha(2, 'after', tiempos.calendario.ultimo_dia_semana)"
                ><v-icon color="info">mdi-chevron-right</v-icon></v-btn
              >
            </v-toolbar>
            <v-container grid-list-xl fluid>
              <v-layout wrap>
                <v-flex lg6 sm6 xs6>
                  <v-card>
                    <v-card-text>
                      <mini-statistic
                        icon="mdi-clock"
                        :title="tiempos.promedio.tespera"
                        sub-title="T. Espera"
                        :color="tiempos.promedio.tespera_color"
                      />
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex lg6 sm6 xs6>
                  <v-card>
                    <v-card-text>
                      <mini-statistic
                        icon="mdi-av-timer"
                        :title="tiempos.promedio.duracion"
                        :sub-title="$t('common.duracion')"
                        :color="tiempos.promedio.duracion_color"
                      />
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
            <tabla-generica
              :loading="loading"
              :headers-tabla="tiempos.headers"
              :items-tabla="tiempos.body"
              :max-cols="3"
            />
          </span>
        </v-card>
      </v-expansion-panel>
      <v-expansion-panel>
        <div slot="header" @click="abrirInforme(3)">
          <h4>{{ $t('reportes.cumplimiento_servicio') }}</h4>
        </div>
        <v-card>
          <v-divider />
          <br />
          <v-layout class="filtroInforme">
            <v-flex xs6 sm8 lg8>
              <v-select
                v-model="profesional_id"
                :items="profesionales"
                :label="$t('user.prof_sanitario')"
                style="margin-top: 2px"
                auto
                required
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex xs6 sm4 lg4 class="text-sm-right">
              <v-btn :disabled="disabledButton" color="primary" @click="filtrarInforme(3)">
                <span><v-icon>mdi-magnify</v-icon>{{ $t('common.filtrar') }}</span>
              </v-btn>
            </v-flex>
          </v-layout>
          <span v-if="cumplimientos.cargado">
            <v-toolbar dense color="transparent">
              <v-btn icon @click="modificarFecha(3, 'before', cumplimientos.calendario.primer_dia_semana)"
                ><v-icon color="info">mdi-chevron-left</v-icon></v-btn
              >
              <v-spacer />
              {{
                $t('common.semana') +
                  ' ' +
                  cumplimientos.calendario.primer_dia_front +
                  ' - ' +
                  cumplimientos.calendario.ultimo_dia_front
              }}
              <v-spacer />
              <v-btn icon @click="modificarFecha(3, 'after', cumplimientos.calendario.ultimo_dia_semana)"
                ><v-icon color="info">mdi-chevron-right</v-icon></v-btn
              >
            </v-toolbar>
            <tabla-generica
              :loading="loading"
              :headers-tabla="cumplimientos.headers"
              :items-tabla="cumplimientos.body"
              :max-cols="3"
            />
          </span>
        </v-card>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </div>
</template>

<script>
import MiniStatistic from '@/components/widgets/statistic/MiniStatistic.vue';
import TablaSemanal from '@/components/common/TablaSemanal.vue';
import TablaGenerica from '@/components/common/TablaGenerica.vue';
import { nameEnv, reportesMedyolaUrl, getHeader } from '../../config/config';

export default {
  name: 'ReportesMedyola',
  components: {
    TablaSemanal,
    TablaGenerica,
    MiniStatistic,
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: false,
    disabledButton: true,
    snackbar: false,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icono: 'mdi-alert',
      color: 'info',
    },
    pagination: {
      rowsPerPage: 10,
    },
    precargado: [false, false, false, false, true, false],
    disponibilidad: { headers: [], body: [] },
    productividad: { headers: [], body: [], cargado: false },
    tiempos: { headers: [], body: [], cargado: false },
    cumplimientos: { headers: [], body: [], cargado: false },
    totalesDisponibilidad: [],
    profesionales: [],
    profesional_id: 0,
    es_medyola: nameEnv === 'medyola' ? true : false,
    fecha: '',
    cambiarFecha: '',
  }),

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date(_val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    fechaNacimientoMenu(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
          return;
        });
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    abrirInforme(tab) {
      if (this.profesionales.length > 0) {
        this.precargado[1] = true;
        this.precargado[2] = true;
      }
      if (!this.precargado[tab]) {
        this.loading = true;
        this.precargado[tab] = true;
        const tipoAccion = tab !== 1 && tab !== 2 && tab !== 3 ? 'informe' : 'preload';
        this.$http
          .post(
            reportesMedyolaUrl,
            { user_id: this.authUser.id, tab: tab, accion: tipoAccion },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data) {
              this.loading = false;
              if (tab === 0) {
                this.disponibilidad = response.data.disponibilidad;
              } else if (tab === 1 || tab === 2 || tab === 3) {
                this.profesionales = response.data.profesionales;
              }
              this.disabledButton = false;
            }
          });
      }
    },

    filtrarInforme(tab) {
      if (tab === 1 && this.profesional_id === 0) {
        this.alerta.texto = this.$t('reportes.seleccione_profesional');
        this.alerta.color = 'warning';
        this.snackbar = true;
      } else {
        this.disabledButton = true;
        this.loading = true;
        this.$http
          .post(
            reportesMedyolaUrl,
            {
              user_id: this.authUser.id,
              tab: tab,
              accion: 'informe',
              profesional_id: this.profesional_id,
              cambiarFecha: this.cambiarFecha,
              fecha: this.fecha,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data) {
              if (tab === 1) {
                this.productividad = response.data.productividad;
              } else if (tab === 2) {
                this.tiempos = response.data.tiempos;
              } else if (tab === 3) {
                this.cumplimientos = response.data.cumplimientos;
              }
              this.loading = false;
              this.disabledButton = false;
            }
          });
      }
    },

    modificarFecha(tab, accion, fecha) {
      this.fecha = fecha;
      this.cambiarFecha = accion;
      this.filtrarInforme(tab);
    },
  },
};
</script>
<style scoped lang="css">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s ease;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.loading_usuario {
  margin-top: 30px;
  text-align: center;
}
.form_datos_personales,
.form_historial_clinico {
  padding: 10px;
}
.label_genero,
.label_estado {
  margin-bottom: 0px;
}
.radio_genero,
.radio_estado {
  margin-top: 3px;
}
.md-card-profile {
  width: 96%;
  margin: -50px auto 0;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.avatar {
  z-index: 1;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.form_marketplace {
  margin-top: 40px;
}
.foto_usuario:hover {
  box-shadow: 0 10px 30px -12px #2196f369, 0 4px 25px 0 #2196f370, 0 8px 10px -5px rgba(33, 150, 243, 0.3);
}
.card_mensajeria {
  background: linear-gradient(to right top, #920837, #f16d6d);
}
.card_videoconsulta {
  background: linear-gradient(to right top, #0d4080, #98caf1);
}
.card_packs {
  background: linear-gradient(to right top, #af8e27, #f1e79f);
}
.card_recetas {
  background: linear-gradient(to right top, #197d09, #b2fbba);
}
.card_landings {
  background: linear-gradient(to right top, #28479c, #808dc9);
}
.foto_avatar {
  position: relative;
  z-index: 1;
}
.ocultar_croppa {
  display: none;
}
.font-red {
  color: #bc2b36;
}
.btn_upload_imagen {
  position: absolute !important;
  z-index: 100;
  top: 135px;
  margin-left: 83px;
  -webkit-animation: enfasis_btn 1s infinite alternate;
}
.filtroInforme {
  padding: 0px 30px;
}
@keyframes enfasis_btn {
  from {
    width: 40px;
    height: 40px;
    top: 135px;
    margin-left: 83px;
  }
  to {
    width: 50px;
    height: 50px;
    top: 128px;
    margin-left: 78px;
  }
}
</style>
