<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
        <v-toolbar flat class="transparent">
          <h1 class="headline">{{ $t('menu.reports') }}</h1>
          <v-spacer />
        </v-toolbar>
        <div id="reportes" class="reportes">
          <div v-if="loading" class="text-center">
            <v-progress-circular :size="60" color="primary" indeterminate />
          </div>
          <div v-if="!loading" class="layout row media-layout">
            <v-container grid-list-xl fluid>
              <v-layout wrap>
                <template v-if="!es_vithas">
                  <v-flex v-for="(item, index) in dashboard.resumenTotal" :key="index" lg3 sm6 xs12>
                    <mini-statistic
                      :icon="item.icono"
                      :title="item.total"
                      :sub-title="item.titulo"
                      :color="item.color"
                    />
                  </v-flex>
                </template>

                <v-flex v-if="es_vithas && es_admin" xs12>
                  <reportesVithas :dash="dashboard" />
                </v-flex>

                <v-flex v-if="es_medyola" xs12>
                  <reportesMedyola />
                </v-flex>
                <v-flex v-if="es_dingdoc && es_admin" xs12>
                  <reportesDingdoc />
                </v-flex>
                <v-flex v-if="es_doctorclic && es_admin" xs12 sm12>
                  <v-card class="mb-4">
                    <v-toolbar color="primary" dark flat dense cad>
                      <v-toolbar-title class="subheading">{{ $t('reportes.informe_pontgrup') }}</v-toolbar-title>
                      <v-spacer />
                    </v-toolbar>
                    <v-card-text>
                      <v-layout>
                        <v-flex xs12 sm4 lg4>
                          <v-menu
                            ref="finiMenu"
                            v-model="finiMenu"
                            class="mr-2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            full-width
                            max-width="290px"
                            :return-value.sync="fini"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="iniFormatted"
                                :label="$t('common.fecha_inicio') + ' *'"
                                append-icon="mdi-calendar"
                                readonly
                                class="input_fnacimiento"
                                v-on="on"
                                @blur="fini = parseDate(iniFormatted)"
                              />
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="fini"
                              first-day-of-week="1"
                              :locale="this.$i18n.locale"
                              min="2017-01-01"
                              no-title
                              scrollable
                              @input="$refs.finiMenu.save(fini)"
                            />
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 sm4 lg4>
                          <v-menu
                            ref="ffinMenu"
                            v-model="ffinMenu"
                            class="mr-2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            full-width
                            max-width="290px"
                            :return-value.sync="ffin"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="finFormatted"
                                :label="$t('common.fecha_fin') + ' *'"
                                append-icon="mdi-calendar"
                                readonly
                                class="input_fnacimiento"
                                v-on="on"
                                @blur="ffin = parseDate(finFormatted)"
                              />
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="ffin"
                              first-day-of-week="1"
                              :locale="this.$i18n.locale"
                              min="2017-01-01"
                              no-title
                              scrollable
                              @input="$refs.ffinMenu.save(ffin)"
                            />
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 sm4 lg4 class="text-sm-right">
                          <v-btn :disabled="disabledButton" :color="'info'" @click="getInforme()">
                            <span><v-icon>mdi-magnify</v-icon>{{ $t('common.filtrar') }}</span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-card-text>

                    <v-divider />
                    <v-card-text class="">
                      <v-data-table
                        :headers="headersDrClic"
                        :items="sums"
                        :items-per-page-options="[10, 25, { text: $t('datatable.all'), value: -1 }]"
                        class="elevation-1"
                        :items-per-page-text="$t('datatable.rows_per_page')"
                        :options.sync="pagination"
                      >
                        <template v-slot:item="props">
                          <tr>
                            <td class="text-center">
                              {{ props.item.fechaFormat }}
                            </td>
                            <td class="text-center">
                              {{ props.item.numAltas }}
                            </td>
                            <td class="text-center">
                              {{ props.item.numBajas }}
                            </td>
                            <td class="text-center">
                              {{ props.item.numRenovaciones }}
                            </td>
                          </tr>
                        </template>

                        <template slot="footer">
                          <tr>
                            <td class="text-center">
                              <strong>{{ $t('reportes.total') }}</strong>
                            </td>
                            <td class="text-center">
                              <strong>{{ totales.altas }}</strong>
                            </td>
                            <td class="text-center">
                              <strong>{{ totales.bajas }}</strong>
                            </td>
                            <td class="text-center">
                              <strong>{{ totales.renov }}</strong>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import MiniStatistic from '@/components/widgets/statistic/MiniStatistic.vue';
import ReportesMedyola from '@/components/reportes/Medyola.vue';
import ReportesDingdoc from '@/components/reportes/Dingdoc.vue';
import ReportesVithas from '@/components/reportes/Vithas.vue';
import { nameEnv, reportesUrl, sumPontgrupUrl, getHeader } from '../config/config';

export default {
  name: 'Reportes',
  components: {
    MiniStatistic,
    ReportesMedyola,
    ReportesDingdoc,
    ReportesVithas,
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    pagination: {
      itemsPerPage: 10,
    },
    es_iconnecta: false,
    es_medyola: nameEnv === 'medyola',
    es_dingdoc: nameEnv === 'dingdoc',
    es_doctorclic: nameEnv === 'doctorclic',
    es_vithas: nameEnv.includes('vithas'),
    headers: [],
    sums: [],
    totales: [],
    datatable: {
      rows_per_page: 'Rows per Page',
    },
    dashboard: {
      resumenTotal: [],
      elastic: [],
    },
    es_admin:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 1,
    es_rrhh:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 4,
    headersDrClic: [
      { text: 'Fecha', align: 'center', value: 'fecha' },
      { text: 'Número de Altas', align: 'center', value: 'numAltas' },
      { text: 'Número de Bajas', align: 'center', value: 'numBajas' },
      {
        text: 'Número de Renovaciones',
        align: 'center',
        value: 'numRenovaciones',
      },
    ],
    disabledButton: true,
    loadingPontgrup: true,
    fini: null,
    finiMenu: false,
    ffinMenu: false,
    ffin: null,
    iniFormatted: null,
    finFormatted: null,
    fmax: null,
  }),
  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
    }),
  },
  watch: {
    fini() {
      this.iniFormatted = this.formatDate(this.fini);
    },
    ffin() {
      this.finFormatted = this.formatDate(this.ffin);
    },
  },
  mounted() {
    const date = new Date();
    const primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
    const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.fmax = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.fini = primerDia.getFullYear() + '-' + (primerDia.getMonth() + 1) + '-' + primerDia.getDate();
    this.ffin = ultimoDia.getFullYear() + '-' + (ultimoDia.getMonth() + 1) + '-' + ultimoDia.getDate();
    if (this.es_doctorclic) {
      this.getInforme();
    }
    this.getResumenTotal();
  },
  created() {
    const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
    this.datatable.rows_per_page = this.$t('datatable.rows_per_page');

    this.$http.get(reportesUrl, { headers: getHeader() }).then(response => {
      if (response.status === 200 && response.data) {
        this.loading = false;
        if (
          nameEnv === 'iconnecta' ||
          (authUser !== null &&
            (authUser.nombreEmpresa === 'ICOnnecta' || authUser.nombreEmpresa === 'Docline Hospital'))
        ) {
          this.es_iconnecta = true;
          this.dashboard.resumenTotal = [
            {
              icono: 'mdi-account-group',
              total: response.data.totales.pacientes,
              titulo: this.$t('menu.patients'),
              color: 'indigo',
            },
            {
              icono: 'mdi-video-wireless',
              total: response.data.totales.videoconsultas,
              titulo: this.$t('menu.videocam'),
              color: 'light-blue',
            },
          ];
        } else if (
          this.es_doctorclic &&
          !(response.data.totales.empresa && response.data.totales.empresa['id'] === 19)
        ) {
          this.dashboard.resumenTotal = [
            {
              icono: 'mdi-account-group',
              total: response.data.totales.pacientes,
              titulo: this.$t('menu.patients') + ' Doctorclic',
              color: 'indigo lighten-1',
            },
            {
              icono: 'mdi-account-group',
              total: response.data.totales.pacientes_pontgrup,
              titulo: this.$t('menu.patients') + ' Pontgrup',
              color: 'indigo darken-1',
            },
            {
              icono: 'mdi-video-wireless',
              total: response.data.totales.videoconsultas,
              titulo: this.$t('common.num') + ' ' + this.$t('menu.videocam'),
              color: 'light-blue',
            },
            {
              icono: 'mdi-forum',
              total: response.data.totales.mensajes,
              titulo: this.$t('common.num') + ' ' + this.$t('mensajeria.mensajes'),
              color: 'pink',
            },
          ];

          if (this.es_rrhh) {
            this.dashboard.resumenTotal = [
              {
                icono: 'mdi-account-group',
                total: response.data.totales.pacientes,
                titulo: this.$t('menu.patients') + ' Doctorclic',
                color: 'indigo lighten-1',
              },
              {
                icono: 'mdi-video-wireless',
                total: response.data.totales.videoconsultas,
                titulo: this.$t('common.num') + ' ' + this.$t('menu.videocam'),
                color: 'light-blue',
              },
              {
                icono: 'mdi-forum',
                total: response.data.totales.mensajes,
                titulo: this.$t('common.num') + ' ' + this.$t('mensajeria.mensajes'),
                color: 'pink',
              },
            ];
          }

          this.loadingPontgrup = true;
          this.headersDrClic[0]['text'] = this.$t('common.date');
          this.headersDrClic[1]['text'] = this.$t('reportes.num_altas');
          this.headersDrClic[2]['text'] = this.$t('reportes.num_bajas');
          this.headersDrClic[3]['text'] = this.$t('reportes.num_renov');
        } else {
          if (this.es_doctorclic && response.data.totales.empresa && response.data.totales.empresa['id'] === 19) {
            // pontgrup
            this.dashboard.resumenTotal[0].total = response.data.totales.pacientes_pontgrup;
          } else {
            this.dashboard.resumenTotal[0].total = response.data.totales.pacientes;
          }
          this.dashboard.resumenTotal[1].total = response.data.totales.recetas;
          this.dashboard.resumenTotal[2].total = response.data.totales.videoconsultas;

          this.dashboard.resumenTotal[0].titulo = this.$t('menu.patients');
          this.dashboard.resumenTotal[1].titulo = this.$tc('common.prescription', 2);
          this.dashboard.resumenTotal[2].titulo = this.$t('menu.videocam');

          if (this.permissions.messaging) {
            this.dashboard.resumenTotal[3].total = response.data.totales.mensajes;
            this.dashboard.resumenTotal[3].titulo = this.$t('mensajeria.mensajes');
          }

          //Dashboard Vithas
          this.dashboard.elastic = response.data.totales.elastic;
          this.dashboard.elastic.num_pacientes = response.data.totales.pacientes;
          this.dashboard.elastic.num_videoconsultas = response.data.totales.videoconsultas;
          this.dashboard.elastic.num_mensajes = response.data.totales.mensajes;
        }

        if (
          typeof response.data.totales.professionals !== 'undefined' &&
          response.data.totales.professionals !== null
        ) {
          const professionals = {
            color: 'orange',
            icono: 'mdi-account-group',
            titulo: this.$t('menu.users'),
            total: response.data.totales.professionals,
          };
          this.dashboard.resumenTotal.unshift(professionals);
        }
      }
    });
  },
  methods: {
    getResumenTotal() {
      const resumenTotal = [
        {
          icono: 'mdi-account-group',
          total: '0',
          titulo: 'Pacientes',
          color: 'indigo',
        },
        {
          icono: 'mdi-stethoscope',
          total: '0',
          titulo: 'Recetas',
          color: 'green',
        },
        {
          icono: 'mdi-video-wireless',
          total: '0',
          titulo: 'Videoconsultas',
          color: 'light-blue',
        },
      ];

      if (this.permissions.messaging) {
        resumenTotal.push({
          icono: 'mdi-forum',
          total: '0',
          titulo: 'Mensajes',
          color: 'pink',
        });
      }

      this.dashboard.resumenTotal = resumenTotal;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    getInforme() {
      this.disabledButton = true;
      this.loadingPontgrup = true;
      this.datatable.rows_per_page = this.$t('datatable.rows_per_page');
      this.$http
        .post(
          sumPontgrupUrl,
          {
            id: this.authUser.id,
            fini: this.fini,
            ffin: this.ffin,
            accion: 'sumPontgrup',
          },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data) {
            this.sums = response.data.sum;
            this.totales = response.data.totales;
          }
          this.loadingPontgrup = false;
          this.disabledButton = false;
        });
    },
  },
};
</script>
<style scoped lang="css"></style>
