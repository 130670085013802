<template>
  <div id="tablaGenerica">
    <v-data-table
      :headers="headersTabla"
      :items="itemsTabla"
      :loading="loading"
      :loading-text="$t('common.cargando')"
      :items-per-page-options="[10, 25, { text: $t('datatable.all'), value: -1 }]"
      class="elevation-1 filtroInforme"
      :items-per-page-text="$t('datatable.rows_per_page')"
      item-key="name"
      :options.sync="pagination"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center">{{ item.col1 }}</td>
          <td class="text-center">{{ item.col2 }}</td>
          <td class="text-center">{{ item.col3 }}</td>
        </tr>
      </template>

      <template slot="no-data">
        {{ $t('common.no_registros') }}
      </template>

      <template slot="pageText" slot-scope="props">
        {{ props.pageStart }} - {{ props.pageStop }} {{ $t('common.of') }} {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'TablaGenerica',
  props: {
    headersTabla: {
      type: Array,
    },
    itemsTabla: {
      type: Array,
    },
    maxCols: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },
  data: () => ({
    pagination: {
      itemsPerPage: 10,
    },
  }),
};
</script>
<style scoped lang="css"></style>
