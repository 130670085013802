<template>
  <div id="reportesDingdoc">
    <v-layout align-center justify-center>
      <v-flex xs12>
        <v-card>
          <v-card-text>
            <v-layout>
              <v-flex xs12 sm6 lg6>
                <v-menu
                  ref="finiMenu"
                  v-model="finiMenu"
                  class="mr-2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  :return-value.sync="fini"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="iniFormatted"
                      :label="$t('common.fecha_inicio') + ' *'"
                      append-icon="mdi-calendar"
                      readonly
                      class="input_fnacimiento"
                      v-on="on"
                      @blur="fini = parseDate(iniFormatted)"
                    />
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="fini"
                    first-day-of-week="1"
                    :locale="this.$i18n.locale"
                    min="2017-01-01"
                    :max="fmax.toISOString().substr(0, 10)"
                    no-title
                    scrollable
                    @input="$refs.finiMenu.save(fini)"
                  />
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-menu
                  ref="ffinMenu"
                  v-model="ffinMenu"
                  class="mr-2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  :return-value.sync="ffin"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="finFormatted"
                      :label="$t('common.fecha_fin') + ' *'"
                      append-icon="mdi-calendar"
                      readonly
                      class="input_fnacimiento"
                      v-on="on"
                      @blur="ffin = parseDate(finFormatted)"
                    />
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="ffin"
                    first-day-of-week="1"
                    :locale="this.$i18n.locale"
                    min="2017-01-01"
                    :max="fmax.toISOString().substr(0, 10)"
                    no-title
                    scrollable
                    @input="$refs.ffinMenu.save(ffin)"
                  />
                </v-menu>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs6 sm8 lg8>
                <v-select
                  v-model="profesional_id"
                  :items="profesionales"
                  :label="$t('user.prof_sanitario')"
                  style="margin-top: 2px"
                  auto
                  required
                  item-text="name"
                  item-value="id"
                />
              </v-flex>
              <v-flex xs6 sm4 lg4 class="text-sm-right">
                <v-btn :disabled="disabledButton" color="primary" @click="filtrarInforme(1)">
                  <span><v-icon>mdi-magnify</v-icon>{{ $t('common.filtrar') }}</span>
                </v-btn>
              </v-flex>
            </v-layout>
            <span v-if="productividad.cargado">
              <tabla-generica
                :loading="loading"
                :headers-tabla="productividad.headers"
                :items-tabla="productividad.body"
              />
            </span>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </div>
</template>

<script>
import TablaGenerica from '@/components/common/TablaGenerica.vue';
import { nameEnv, reportesDingdocUrl, getHeader } from '../../config/config';

export default {
  name: 'ReportesDingdoc',
  components: {
    TablaGenerica,
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    fini: null,
    finiMenu: false,
    ffinMenu: false,
    ffin: null,
    iniFormatted: null,
    finFormatted: null,
    fmax: null,
    loading: false,
    disabledButton: true,
    snackbar: false,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icono: 'mdi-alert',
      color: 'info',
    },
    pagination: {
      rowsPerPage: 10,
    },
    precargado: [false, false, false, false, true, false],
    disponibilidad: { headers: [], body: [] },
    productividad: { headers: [], body: [], cargado: false },
    tiempos: { headers: [], body: [], cargado: false },
    cumplimientos: { headers: [], body: [], cargado: false },
    totalesDisponibilidad: [],
    profesionales: [],
    profesional_id: 0,
    es_medyola: nameEnv === 'medyola' ? true : false,
    fecha: '',
    cambiarFecha: '',
  }),

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    fini(_val) {
      this.iniFormatted = this.formatDate(this.fini);
    },
    ffin(_val) {
      this.finFormatted = this.formatDate(this.ffin);
    },
    date(_val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    fechaNacimientoMenu(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
          return;
        });
    },
  },

  mounted() {
    this.precargarInforme(1);
  },

  created() {
    const date = new Date();
    const primerDia = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.fmax = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.fini = primerDia.getFullYear() + '-' + (primerDia.getMonth() + 1) + '-' + primerDia.getDate();
    this.ffin = ultimoDia.getFullYear() + '-' + (ultimoDia.getMonth() + 1) + '-' + ultimoDia.getDate();
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    precargarInforme(tab) {
      if (this.profesionales.length > 0) {
        this.precargado[1] = true;
        this.precargado[2] = true;
      }
      if (!this.precargado[tab]) {
        this.loading = true;
        this.precargado[tab] = true;
        const tipoAccion = tab !== 1 && tab !== 2 && tab !== 3 ? 'informe' : 'preload';
        this.$http
          .post(
            reportesDingdocUrl,
            { user_id: this.authUser.id, tab: tab, accion: tipoAccion },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data) {
              this.loading = false;
              if (tab === 1) {
                this.profesionales = response.data.profesionales;
              }
              this.disabledButton = false;
            }
          });
      }
    },

    filtrarInforme(tab) {
      const fechaInicio = new Date(this.fini).getTime();
      const fechaFin = new Date(this.ffin).getTime();

      const diff = fechaFin - fechaInicio;
      const dias = diff / (1000 * 60 * 60 * 24);

      if (tab === 1 && this.profesional_id === 0) {
        this.alerta.texto = this.$t('reportes.seleccione_profesional');
        this.alerta.color = 'warning';
        this.snackbar = true;
      }
      if (dias > 91) {
        this.alerta.texto = this.$t('facturacion.rango_fechas', { num: '90' });
        this.alerta.color = 'warning';
        this.alerta.icono = 'mdi-calendar-range';
        this.snackbar = true;
      } else {
        this.disabledButton = true;
        this.loading = true;
        this.$http
          .post(
            reportesDingdocUrl,
            {
              user_id: this.authUser.id,
              tab: tab,
              accion: 'informe',
              profesional_id: this.profesional_id,
              cambiarFecha: this.cambiarFecha,
              fechaInicio: this.fini,
              fechaFin: this.ffin,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data) {
              if (tab === 1) {
                this.productividad = response.data.productividad;
              }
              this.loading = false;
              this.disabledButton = false;
            }
          });
      }
    },

    modificarFecha(tab, accion, fecha) {
      this.fecha = fecha;
      this.cambiarFecha = accion;
      this.filtrarInforme(tab);
    },
  },
};
</script>
<style scoped lang="css">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s ease;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.loading_usuario {
  margin-top: 30px;
  text-align: center;
}
.form_datos_personales,
.form_historial_clinico {
  padding: 10px;
}
.label_genero,
.label_estado {
  margin-bottom: 0px;
}
.radio_genero,
.radio_estado {
  margin-top: 3px;
}
.md-card-profile {
  width: 96%;
  margin: -50px auto 0;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.avatar {
  z-index: 1;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.form_marketplace {
  margin-top: 40px;
}
.foto_usuario:hover {
  box-shadow: 0 10px 30px -12px #2196f369, 0 4px 25px 0 #2196f370, 0 8px 10px -5px rgba(33, 150, 243, 0.3);
}
.card_mensajeria {
  background: linear-gradient(to right top, #920837, #f16d6d);
}
.card_videoconsulta {
  background: linear-gradient(to right top, #0d4080, #98caf1);
}
.card_packs {
  background: linear-gradient(to right top, #af8e27, #f1e79f);
}
.card_recetas {
  background: linear-gradient(to right top, #197d09, #b2fbba);
}
.card_landings {
  background: linear-gradient(to right top, #28479c, #808dc9);
}
.foto_avatar {
  position: relative;
  z-index: 1;
}
.ocultar_croppa {
  display: none;
}
.font-red {
  color: #bc2b36;
}
.btn_upload_imagen {
  position: absolute !important;
  z-index: 100;
  top: 135px;
  margin-left: 83px;
  -webkit-animation: enfasis_btn 1s infinite alternate;
}
.filtroInforme {
  padding: 0px 30px;
}
@keyframes enfasis_btn {
  from {
    width: 40px;
    height: 40px;
    top: 135px;
    margin-left: 83px;
  }
  to {
    width: 50px;
    height: 50px;
    top: 128px;
    margin-left: 78px;
  }
}
</style>
