
import Vue from 'vue';
import { getLocaleFullFormat } from '@/utils';

export default Vue.extend({
  name: 'FilterReportsByDate',

  data() {
    return {
      date1: '' as string,
      date2: '' as string,
      menu1: false as boolean,
      menu2: false as boolean,
    };
  },

  computed: {
    locale(): string {
      return getLocaleFullFormat(this.$i18n.locale);
    },
  },

  methods: {
    handleChange(): void {
      this.$emit('change', { from: this.date1, to: this.date2 });
    },

    handleClear(item: string): void {
      const prop = `date${item}`;
      this[prop] = '';
      this.handleChange();
    },
  },
});
